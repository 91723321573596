import React from "react";

function Failed() {
  return (
    <div className="text-center">
      <p className="text-red-500">Something wrong! Your message is not sent. <span>&#10008;</span></p>
    </div>
  );
}

export default Failed;
