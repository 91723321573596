import React from "react";

function Success() {
  return (
    <div className="text-center">
      <p className="text-green-500">Your message is sent! <span>&#10004;</span></p>
    </div>
  );
}

export default Success;
